import { WidgetViewModel } from '../../viewModel/viewModel';
import { Resources } from '../../types/types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk/dist/src/WixOOISDKAdapter';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookOnline/settingsParams';
import { ScheduleServer } from '@wix/ambassador-schedule-server/http';
import {
  ListSchedulesRequest,
  ListSchedulesResponse,
} from '@wix/ambassador-schedule-server/types';
import { ServiceType } from '@wix/bookings-uou-types';
import { CoursesAvailability } from './CoursesAvailability';

export type CourseAvailabilityAction = () => void;

interface Params {
  widgetViewModel: WidgetViewModel;
  filteredResources: Resources;
  wixSdkAdapter: WixOOISDKAdapter;
  setProps: Function;
  flowAPI: ControllerFlowAPI;
}

function cacheCourseAvailability(
  flowAPI: ControllerFlowAPI,
  coursesAvailability: CoursesAvailability,
) {
  flowAPI.controllerConfig.platformAPIs.storage.memory.setItem(
    `bookings.courseAvailability-${flowAPI.controllerConfig.compId}`,
    JSON.stringify(coursesAvailability),
  );
}

export function getCourseAvailabilityFromCache(flowAPI: ControllerFlowAPI) {
  try {
    const courseAvailability =
      flowAPI.controllerConfig.platformAPIs.storage.memory.getItem(
        `bookings.courseAvailability-${flowAPI.controllerConfig.compId}`,
      );
    return JSON.parse(courseAvailability || 'null');
  } catch (e) {
    return null;
  }
}

async function fetchCourseAvailability(
  filteredResources: Resources,
  instance: string,
) {
  const schedulesService = ScheduleServer('/_api/schedule-server/').Schedules();
  const scheduleOwnerIds = filteredResources.offerings
    .filter((offering) => offering.type === ServiceType.COURSE)
    .map(({ id }) => id);
  const listSchedulesRequest: ListSchedulesRequest = { scheduleOwnerIds };
  const { schedules }: ListSchedulesResponse = await schedulesService({
    authorization: instance,
  }).list(listSchedulesRequest);
  const coursesAvailability: CoursesAvailability = schedules!.reduce(
    (acc, s) => {
      acc[s?.scheduleOwnerId!] = {
        spotsLeft: s?.capacity! - s?.totalNumberOfParticipants!,
      };
      return acc;
    },
    {} as CoursesAvailability,
  );
  return coursesAvailability;
}

export async function getCourseAvailability(
  flowAPI: ControllerFlowAPI,
  filteredResources: Resources,
): Promise<CoursesAvailability | undefined> {
  const {
    experiments,
    controllerConfig: {
      appParams: { instance },
    },
  } = flowAPI;

  if (!experiments.enabled('specs.bookings.courseAvailability')) {
    return undefined;
  }
  try {
    let coursesAvailability: CoursesAvailability =
      getCourseAvailabilityFromCache(flowAPI);
    if (!coursesAvailability) {
      coursesAvailability = await fetchCourseAvailability(
        filteredResources,
        instance,
      );
      cacheCourseAvailability(flowAPI, coursesAvailability);
    }
    return coursesAvailability;
  } catch (e) {
    // TODO: report an error?
  }
}

export const createCourseAvailabilityAction = ({
  widgetViewModel,
  filteredResources,
  setProps,
  flowAPI,
}: Params) => {
  return async () => {
    const coursesAvailability = await getCourseAvailability(
      flowAPI,
      filteredResources,
    );
    if (coursesAvailability) {
      setProps({
        widgetViewModel: { ...widgetViewModel, coursesAvailability },
      });
    }
  };
};
